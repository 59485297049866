<template>
  <div class="classifyGoods">
    <loginDialog ref="dialog"/>
    <div v-for="(item, index) in goodsList" :key="index" :style="index===goodsList.length-1?'margin-bottom:30px;':''"
         class="classify">
      <!-- 头部 -->
      <div class="head">
        <!-- 分类名 -->
        <div class="title" :style="{ background: item.default_back_color }">
          <h3>{{ item.translate_name }}</h3>
        </div>
        <!-- 子分类菜单 -->
        <div class="menus">
          <div class="flex">
            <div v-for="(menu, menus) in item.childs" :key="menus" :class="{ checked: item.index == menus }"
                 class="menu"
                 @click="$fun.routerToPage('/CommoditySearch?keyword=' + menu.translate_name)"
                 @mouseover="getResources(menu, index, menus)">
              <div :style="{
              background:
                item.index == menus ? item.trigger_back_color : 'transparent',
              color: item.index == menus ? item.default_back_color : '',
            }" class="menus-name">
                {{ menu.translate_name }}
              </div>
              <div :style="{
              background:
                item.index == menus ? item.default_back_color : 'transparent',
            }" class="border"></div>
            </div>
          </div>
          <div class="cursorPointer color333 fontSize14" style="line-height: 48px"
               @click="$fun.routerToPage('/CommoditySearch?keyword=' + item.childs[item.index].translate_name)">
            {{ $fanyi('查看更多') }}>>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <!-- 左边背景 -->
        <div class="bg">
          <el-image :src="item.list_banner">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </div>
        <!-- 右边商品组 -->
        <div class="goodsList">
          <div class="item" v-for="(goods, goodsIndex) in item.resources" :key="goodsIndex"
               :data-aplus-report="goods.traceInfo" data-tracker="offer" @click="toCommodityDetails(goods)"
               @mouseenter="goodsMouseenter(index,goodsIndex)"
               @mouseleave="goodsMouseleave(index,goodsIndex)">
            <!-- 图片 -->
            <div class="img" style="position: relative">
              <el-image :src="goods.goods_pic">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <div v-show="goods.hoverStatus === true" class="findSimilarityContainer"
                   @click.stop="imgSearchGoods(goods.goods_pic)">{{ $fanyi("查找相似") }}
              </div>
            </div>
            <!-- 商品简介 -->
            <div class="flexAndCenter">
              <div :title="goods.goods_title" class="title">
                <span v-if="goods.goods_type==1688" class="type">1688</span>
                {{ goods.goods_title }}
              </div>
            </div>
            <!-- 价格 -->
            <div class="price">
              {{ goods.goods_price }}{{ $fanyi('元') }}（{{
                (goods.goods_price * exchangeRate).toFixed(0)
              }}{{ $fanyi('円') }}）
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>
<script>
import loginDialog from "@/components/public/loginDialog";

export default {
  data() {
    return {
      goodsList: [],
    };
  },
  components: {
    loginDialog
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    },
  },
  created() {
    this.getRecommendedGoods();
  },
  methods: {
    getRecommendedGoods() {
      this.$api.getRecommendedGoods().then((res) => {
        res.data.forEach((item) => {
          item.index = 0;
          item.childs.sort(duiBi);

          function duiBi(a, b) {
            // time 是时间的字段 a-b 正序 b-a 倒序
            return Date.parse(b.sort) - Date.parse(a.sort);
          }

          item.childs[0].home_goods.forEach((item) => {
            item.hoverStatus = false;
          })
          item.resources = item.childs[0].home_goods;
          item.childs.forEach((items, indexs) => {
            items.index = indexs;
          });
        });
        this.goodsList = res.data;
      });
    },
    toCommodityDetails(val) {
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        return
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过主页商品进入商品详情页访问量统计');
      }
      this.$fun.newToCommodityDetails(val.goods_id, val.goods_type, false, 'index')
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      window.open("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    goodsMouseenter(index, index1) {
      this.goodsList[index].resources[index1].hoverStatus = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index, index1) {
      this.goodsList[index].resources[index1].hoverStatus = false;
      this.$forceUpdate();
    },
    getResources(menu, index, menus) {
      this.goodsList[index].resources = [];
      this.$forceUpdate();
      this.goodsList[index].resources = menu.home_goods;
      this.$forceUpdate();
      this.goodsList[index].index = menus;
    },
  },
};
</script>
<style lang='scss' scoped>
.classifyGoods {
  width: 1400px;
  margin: 0 auto;

  .type {
    width: 46px;
    height: 18px;
    display: inline-block;
    background: #FF4000;
    border-radius: 2px;
    margin-right: 7px;
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
  }

  .classify {
    margin-bottom: 60px;

    .head {
      //   border-bottom: 2px solid #007ad0;
      display: flex;

      .title {
        flex: 1;
        height: 60px;
        font-size: 20px;
        color: #fff;
        line-height: 60px;
        text-align: center;
        border-radius: 6px;
        transform: translate(0, 1px);
        background-color: #351994;

        h3 {
          width: 300px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .menus {
        flex: 4;
        margin-left: 20px;
        margin-top: 10px;
        border-bottom: 2px solid #fff;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        //align-items: center;

        .menu {
          display: inline-block;
          margin-right: 20px;
          height: 40px;
          white-space: nowrap;
          cursor: pointer;
          position: relative;
          color: #2f2f2f;

          &:nth-last-child(1) {
            margin-right: 0px;
          }

          .menus-name {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 4px;
            font-size: 15px;
          }

          .border {
            position: absolute;
            left: 0;
            bottom: -10px;
            height: 2px;
            width: 100%;
          }
        }

        .checked {
          .menus-name {
            font-weight: 600;
            color: #351994;
            background-color: #e3e0fb;
          }

          .border {
            background-color: #351994;
          }
        }

        .cursorPointer:hover {
          color: #B4272B;
        }
      }
    }

    .content {
      display: flex;
      margin-top: 20px;

      .bg {
        background-color: #fff;
        border-radius: 6px;
        width: 300px;
        height: 580px;
        border: 1px solid #e8e8e8;
        text-align: center;
        line-height: 580px;
        overflow: hidden;

        .el-img {
          width: 298px;
          height: 580px;
        }

        /deep/ .el-image__inner {
          width: 300px;
          height: 580px !important;
        }

        i {
          font-size: 40px;
        }
      }

      .goodsList {
        flex: 4;
        padding-left: 20px;
        display: flex;
        flex-wrap: wrap;

        .findSimilarityContainer {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 32px;
          width: 178px;
          background: #E0787E;
          cursor: pointer;
          color: #fff;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
        }

        .findSimilarityContainer:hover {
          background: #C40622;
        }

        .item {
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          width: 200px;
          margin-right: 20px;
          padding: 10px;
          background-color: #fff;
          margin-bottom: 20px;
          font-size: 14px;
          transition: 0.3s;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 0px 15px rgb(223, 223, 223);
          }

          &:nth-child(5n) {
            margin-right: 0;
          }

          &:nth-child(n + 6) {
            margin-bottom: 0;
          }

          .el-image {
            width: 178px;
            height: 178px;
            cursor: pointer;
            text-align: center;
            line-height: 178px;

            .el-icon-loading {
              font-size: 40px;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .title {
            margin: 10px 0;
            line-height: 20px;
            color: #2f2f2f;

            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            font-size: 13px;
            font-weight: 500;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .price {
            color: #B4272B;
            height: 17px;
            line-height: 17px;
            font-size: 15px;
            font-weight: 500;
            overflow: hidden;

            span {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
