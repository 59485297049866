<template>
  <div id="homePageCon">
    <HomePageTop></HomePageTop>
    <SlideShow></SlideShow>
    <hotSaleProducts/>
    <netEaseYanXuan v-if="netEaseYanXuanShow"/>
    <!--    <indexNavigationDescriptionPage/>-->
    <!--    <recommendCommodities></recommendCommodities>-->
    <ClassifyGoods/>
    <InformBounced></InformBounced>
    <Foot/>
  </div>
</template>


<script>
import optionExplain from "@/components/homePage/optionExplain";
import netEaseYanXuan from "@/components/homePage/netEaseYanXuan"
import palletizedCargo from "@/components/homePage/palletizedCargo.vue";
import hotSaleProducts from "@/components/homePage/hotSaleProducts.vue";
// import indexNavigationDescriptionPage from '../../components/homePage/indexNavigationDescriptionPage'
import HomePageTop from "../../components/head/newHomePageTop.vue";
import SlideShow from "../../components/homePage/slideShow.vue";
// import recommendCommodities from "../../components/homePage/recommendCommodities.vue";
import ourAdvantages from "../../components/homePage/ourAdvantages";
import Foot from "../../components/foot/Foot.vue";
import InformBounced from "../../views/order/InformBounced";
import ClassifyGoods from "../../components/homePage/classifyGoods.vue";

export default {
  components: {
    HomePageTop,
    SlideShow,
    // recommendCommodities,
    ourAdvantages,
    // indexNavigationDescriptionPage,
    optionExplain,
    netEaseYanXuan,
    palletizedCargo,
    hotSaleProducts,
    Foot,
    InformBounced,
    ClassifyGoods,
  },
  data() {
    return {
      netEaseYanXuanShow: false,
      startTimestamp: 0,
      endTimestamp: 0
    }
  },
  created() {
    let japan_user_token = localStorage.getItem('japan_user_token');
    if (japan_user_token != null) {
      let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
      if (userData.operation_id == '18887') {
        this.netEaseYanXuanShow = true
      }
    }
    if (this.$fun.isOfficialWeb()) {
      document.addEventListener('visibilitychange', () => {
        // 页面可见时更新开始时间戳
        if (document.visibilityState === 'visible') {
          this.startTimestamp = Date.now();
        }
        // 页面不可见时更新结束时间戳并计算时长
        if (document.visibilityState === 'hidden') {
          this.endTimestamp = Date.now();
          this.calculateStayDuration();
        }
      });
    }
  },
  methods: {
    calculateStayDuration() {
      if (this.startTimestamp > 0 && this.endTimestamp > 0) {
        __bl.avg('用户停留在rakumart首页停留平均时长统计', (this.endTimestamp - this.startTimestamp) / 1000);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
