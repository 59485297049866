<template>
  <div class="page">
    <loginDialog ref="dialog"/>
    <div class="pageContainer">
      <img alt="" class="logo" src="../../assets/1688/palletizedCargoLogo.png">
      <div class="title">
        <img alt="" src="../../assets/1688/leftSideSymbol.png">
        <div>1688が厳選した高品質商品を早く、安く日本語で簡単検索</div>
        <img alt="" src="../../assets/1688/offsideSymbol.png">
      </div>
      <div class="textList">
        <div class="paddingRight43 textBorderRight">1688の厳選商品</div>
        <div class="paddingLeftAndRight63 textBorderRight">同類商品で一番のコストパフォーマンス</div>
        <div class="paddingLeftAndRight63 textBorderRight">高評価品質工場商品リスト</div>
        <div class="paddingLeftAndRight63 textBorderRight">厳選した高品質商品</div>
        <div class="paddingLeft43">日本語での検索、商品閲覧が可能</div>
      </div>
      <div class="categoryList">
        <div v-for="(item,index) in categoryList" :key="index" class="categoryItem"
             @click="$fun.routerToPage('/CommoditySearch?keyword=' + item.text+'&regionOpp=jpOpp');">
          <img :src="item.url" alt="">
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog},
  data() {
    return {
      categoryList: [{
        url: require('../../assets/1688/mensWear.png'),
        text: 'メンズファッション'
      }, {
        url: require('../../assets/1688/womensWear.png'),
        text: 'レディースファッション'
      }, {
        url: require('../../assets/1688/bagsAndSuitcases.png'),
        text: 'バッグ、キャリーケース'
      }, {
        url: require('../../assets/1688/accessories.png'),
        text: 'ジュエリー 、アクセサリー'
      }, {
        url: require('../../assets/1688/exercise.png'),
        text: 'アウトドア、フィットネス'
      }, {
        url: require('../../assets/1688/furniture.png'),
        text: 'インテリア、家具'
      }, {
        url: require('../../assets/1688/electronics.png'),
        text: '電子製品アクセサリー、周辺機器'
      }, {
        url: require('../../assets/1688/stationery.png'),
        text: '文房具、オフィス用品'
      }]
    }
  },
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  background: #FFFAF4;
  margin-bottom: 100px;

  .pageContainer {
    padding: 100px 0;
    margin: 0 auto;
    width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      height: 45px;
      margin-bottom: 30px;
    }

    .title {
      display: flex;
      margin-bottom: 106px;

      img {
        height: 30px;
        width: 35px;
      }

      div:nth-child(2) {
        color: #333333;
        font-weight: bold;
        font-size: 36px;
        margin: 0 22px;
      }
    }

    .textList {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;
      margin-bottom: 50px;

      div {
        font-size: 14px;
        height: 17px;
        display: flex;
        justify-content: center;
      }

      .textBorderRight {
        border-right: 1px solid #FF4000;
      }

      .paddingRight43 {
        padding-right: 43px;
      }

      .paddingLeft43 {
        padding-left: 43px;
      }

      .paddingLeftAndRight63 {
        padding: 0 63px;
      }
    }

    .searchBoxDiv {
      width: 1000px;
      height: 54px;
      justify-content: space-between;
      display: flex;
      border: 2px solid #FF4000;
      border-radius: 3px;
      margin: 30px 0 60px;

      /deep/ .el-select {
        flex: 0 0 185px;

        .el-input__inner {
          border: none;
          font-size: 13px;
          font-weight: 400 !important;
          height: 50px;
          line-height: 50px;

          &::placeholder {
            font-size: 13px;
            color: black !important;
          }
        }
      }

      .fg {
        height: 20px;
        width: 1px;
        background-color: #6d6d6d;
      }

      /deep/ .el-input {
        flex: 1;

        .el-input__inner {
          border: none;
          font-size: 13px;
          font-weight: 500;
          color: black;
          height: 50px;
          line-height: 50px;
        }
      }

      /deep/ .el-button {
        width: 64px;
        height: 50px;
        border: none;
        background: #FF4000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -2px;
        border-radius: 0;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .categoryList {
      width: 1400px;
      display: flex;
      flex-wrap: wrap;

      .categoryItem {
        width: 320px;
        height: 200px;
        background: rgba(0, 0, 0, 0.5);
        border: 3px solid;
        border-image: linear-gradient(180deg, #FF7E3E, #FF5010, #FE4B0F) 3 3;
        clip-path: inset(0 round 4px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 40px 30px 0;
        box-sizing: border-box;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        font-weight: bold;
        color: #FFFFFF;
        font-size: 18px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          opacity: 0.5;
        }
      }

      .categoryItem:hover {
        transform: scale(1.1, 1.1);
      }

      .text {
        position: absolute;
        top: 45%;
      }

      .categoryItem:nth-child(4n) {
        margin-right: 0;
      }

      .categoryItem:nth-child(n + 6) {
        margin-bottom: 0;
      }
    }
  }
}
</style>