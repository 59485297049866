<template>
  <div>
    <div class="holdOrderDialogVisibleContainer">
      <el-dialog
          :visible.sync="showInformDialogVisible"
          width="700px"
          center>
        <el-carousel
            :autoplay="false"
            indicator-position="outside"
            height="400px"
            :arrow="informList.length > 1 ? 'always' : 'never'">
          <el-carousel-item v-for="(item, index) in informList" :key="index">
            <div class="InformBounced ql-editor">
              <div class="headerTitle">{{ item.title }}</div>
              <div class="borderContainer"></div>
              <div class="con" v-html="item.content"></div>
              <div class="btn" @click="beenRead(item)">既読、次回から表示しない</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showInformDialogVisible: false,
      index: 0,
      informList: [],
    };
  },
  computed: {},
  mounted() {
    this.$api.noticeUnreadNotice().then((res) => {
      if (res.code != 0) return;
      this.informList = res.data;
      if (this.informList.length > 0) {
        this.showInformDialogVisible = true;
      }
    });
  },
  methods: {
    // 获取当前获取的索引
    beenRead(item) {
      this.$api.noticeReadNotice({id: item.id}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi("操作失败"));
        this.$message.success(this.$fanyi("操作成功"));
        this.showInformDialogVisible = false;
        this.$api.noticeUnreadNotice().then((res) => {
          if (res.code != 0) return;
          this.informList = res.data;
          if (this.informList.length > 0) {
            this.showInformDialogVisible = true;
          }
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";

/deep/ .el-carousel__item {

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 0;

    &:hover {
      width: 10px !important;
    }
  }
}

/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/deep/ .is-active .el-carousel__button {
  background: #B4272B;
}


/deep/ .el-carousel__indicators--outside .el-carousel__indicator:hover button {
  opacity: 1;
}

.holdOrderDialogVisibleContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    box-sizing: border-box;
  }

  /deep/ .el-dialog__body, /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__close {
    font-size: 19px;
    color: #B4272B;
    font-weight: bold;
  }


  .holdOrderContainer {
    .icon {
      width: 20px;
      height: 20px;
      background: #B4272B;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      margin-right: 6px;
    }

    .holdOrderHeaderContainer {
      height: 46px;
      padding-left: 26px;
      line-height: 46px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #DFDFDF;

      .holdOrderHeader {
        color: #333;
      }
    }

    .holdOrderText {
      color: #333333;
      font-weight: bold;
      text-align: center;
      margin: 40px 30px;
    }

    .dialog-footer {
      button {
        width: 120px;
        height: 34px;
        border-radius: 8px;
        padding: 0;
        font-size: 14px;
      }

      button:first-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }
}


.headerTitle {
  color: #333333;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.borderContainer {
  width: 36px;
  height: 4px;
  background: #B4272B;
  margin: 7px auto 26px;
}

.con {
  height: 290px;
  overflow-y: scroll;
}

.btn {
  width: 100%;
  height: 34px;
  background: #B4272B;
  border-radius: 8px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
}

/deep/ .el-dialog {
  margin-top: 10vh !important;
}

/deep/ .el-dialog__body {
  padding: 0 30px !important;
}
</style>
