<template>
  <div class="optionExplainPage">
    <div class="optionExplainPageContent">
      <header class="flexAndCenter">
        <div class="left">
          <div :class="checkedIndex===0?'checkedLeftChildrenContainer':'leftChildrenContainer'"
               style="border-radius: 6px 6px 0 0;border-bottom: 1px solid #DFDFDF"
               @mouseenter="updateWebExplain(0,'代购')">
            <div class="webTitle">{{ $fanyi("代购") }}</div>
            <div class="webIcon"></div>
          </div>
          <div :class="checkedIndex===1?'checkedLeftChildrenContainer':'leftChildrenContainer'"
               style="border-bottom: 1px solid #DFDFDF"
               @mouseenter="updateWebExplain(1,'OEM')">
            <div class="webTitle">{{ $fanyi("OEM") }}</div>
            <div class="webIcon"></div>
          </div>
          <div :class="checkedIndex===2?'checkedLeftChildrenContainer':'leftChildrenContainer'"
               style="border-radius: 0 0 6px 6px;"
               @mouseenter="updateWebExplain(2,'无在库')">
            <div class="webTitle">{{ $fanyi("无在库") }}</div>
            <div class="webIcon"></div>
          </div>
        </div>
        <div :style="{'background-image':`url(${require(`../../assets/foot-img/optionExplain/bg${checkedIndex}.png`)}`}"
             class="right"
             @click="pageJump">
          <div class="webName">{{ $fanyi(webName) }}</div>
          <div class="webIcon"></div>
          <div v-if="checkedIndex===1" style="color: #fff">
            オリジナルデザインや品質改善を通して、自社ならではの優位性の高い商品開発が可能です。また、自身のブランドや商標・意匠権取得で相乗り競合を排除し、独占販売も可能です。小ロットでもオーダーメイドが可能なので、ジャンルに特化したプロの通販業者にお勧めです。
          </div>
          <div v-else-if="checkedIndex===2" style="color: #fff">
            商品ページを量産し、販売成約した分だけ仕入れ、顧客直送する在庫を持たない販売手法なので、キャッシュフローや売れ残りのリスクを軽減し、初心者でも気楽にスタートできるビジネス・モデルです
          </div>
          <div v-else style="color: #fff">
            ニーズの高い商品リサーチにより、ピンポイントで商品を選定し、直仕入れ・直販売によるビジネス・モデル。商機を見極め、商材を選別し、コストコントロールで勝負する世界。納期短縮、速戦的戦略が求められる。
          </div>
          <div style="display: flex;justify-content: flex-end">
            <div :style="checkedIndex===1?'margin-top:79px':''" class="pageJumpBtn" @click="pageJump">{{
                checkedIndex === 0 ? 'トップへ' : checkedIndex === 1 ? 'OEM.ODMサービスへ' : '無在庫サービスへ'
              }}
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webName: '代购',
      checkedIndex: 0,
      optionExplainList: [{
        img: require('../../assets/footList/servicios-adicionales/1.png'),
        name: '产品摄影',
        explainText: '专业摄影来完成，无论产品什么类型或者多复杂。',
        borderColor: '#6586DC'
      }, {
        img: require('../../assets/footList/servicios-adicionales/2.png'),
        name: '贴标服务',
        explainText: '支持各个仓库的贴标服务，比如FBA，乐天，雅虎，定制的标签也可以，请联系负责人进行确认。',
        borderColor: '#EFA561'
      }, {
        img: require('../../assets/footList/servicios-adicionales/3.png'),
        name: '更换条码，标签',
        explainText: '',
        borderColor: '#4CA981'
      }, {
        img: require('../../assets/footList/servicios-adicionales/4.png'),
        name: '更换包装袋',
        explainText: '大件物品我们需要单独报价。',
        borderColor: '#6586DC'
      }, {
        img: require('../../assets/footList/servicios-adicionales/5.png'),
        name: '双面打印',
        explainText: '默认为A4纸，其他类型纸张需求请联系负责人确认。',
        borderColor: '#EFA561'
      }, {
        img: require('../../assets/footList/servicios-adicionales/6.png'),
        name: '缝制标签',
        explainText: '缝制个性化标签到您的产品上，如纺织品，衣物等。',
        borderColor: '#4CA981'
      }, {
        img: require('../../assets/footList/servicios-adicionales/7.png'),
        name: '拆卸织物标签',
        explainText: '我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。',
        borderColor: '#6586DC'
      }, {
        img: require('../../assets/footList/servicios-adicionales/8.png'),
        name: '放置吊牌',
        explainText: '在您的产品上按照您的需求放上您的品牌标签。',
        borderColor: '#EFA561'
      }, {
        img: require('../../assets/footList/servicios-adicionales/9.png'),
        name: '移除吊牌',
        explainText: '把商品上带有的吊牌进行移除。',
        borderColor: '#4CA981'
      }, {
        img: require('../../assets/footList/servicios-adicionales/10.png'),
        name: '联合包装',
        explainText: '四件以上的商品联合包装时费用另算，请与负责人联系。',
        borderColor: '#EFA561'
      }, {
        img: require('../../assets/footList/servicios-adicionales/11.png'),
        name: '泡沫袋保护',
        explainText: '大件物品需要单独报价，请与负责人联系',
        borderColor: '#4CA981'
      }]
    }
  },
  methods: {
    //更新网址说明文案
    updateWebExplain(val, name) {
      this.checkedIndex = val;
      this.webName = name;
    },
    pageJump() {
      if (this.checkedIndex !== 0) {
        if (this.checkedIndex == 2) {
          window.open('https://www.rakumart-2c.com/#/');
        } else {
          window.open('https://www.rakumart-oem.com/');
        }
      }
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.optionExplainPage {
  background: #FFFFFF;
  width: 100%;

  .optionExplainPageContent {
    margin: 0 auto;
    width: 1400px;
    padding: 100px 0 100px;

    header {
      .left {
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);

        .leftChildrenContainer, .checkedLeftChildrenContainer {
          width: 360px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #fff;
          padding-bottom: 55px;

          .webTitle {
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding-top: 48px;
          }

          .webIcon {
            width: 20px;
            height: 3px;
            background: #333333;
            margin: 8px 0 19px;
          }

          //
          //.webExplain {
          //  color: #999999;
          //  font-size: 14px;
          //  padding-bottom: 45px;
          //}
        }

        .checkedLeftChildrenContainer {
          background: #B4272B !important;

          .webTitle {
            color: #fff;
          }

          .webIcon {
            background: #fff;
          }

          .webExplain {
            color: #fff;
          }
        }
      }

      .right {
        width: 1040px;
        height: 400px;
        padding: 101px 120px 0 120px;
        cursor: pointer;

        .webName {
          color: #FFFFFF;
          font-weight: bold;
          font-size: 20px;
        }

        .webIcon {
          width: 20px;
          height: 3px;
          background: #B4272B;
          margin: 10px 0 38px;
        }

        .pageJumpBtn {
          width: 180px;
          height: 46px;
          background: #B4272B;
          border-radius: 8px;
          cursor: pointer;
          text-align: center;
          color: #fff;
          font-size: 14px;
          line-height: 46px;
          margin-top: 100px;
        }
      }
    }

    //footer {
    //  margin-top: 200px;
    //
    //  .seamless-warp2 {
    //    width: 1400px;
    //    overflow: hidden;
    //    height: 180px;
    //
    //    .optionExplainContainer {
    //      width: 600px;
    //      height: 180px;
    //      background: #FFFFFF;
    //      border: 1px solid #DFDFDF;
    //      border-radius: 10px;
    //      margin: 0 50px;
    //
    //      .left {
    //        width: 240px;
    //        height: 100%;
    //        min-width: 240px;
    //
    //        img {
    //          width: 180px;
    //          height: 120px;
    //        }
    //      }
    //
    //      .right {
    //        display: flex;
    //        flex-direction: column;
    //        justify-content: space-between;
    //        padding: 30px 26px 0 0;
    //
    //        .rightHeader {
    //          div:first-child {
    //            font-weight: bold;
    //            font-size: 20px;
    //            margin-bottom: 11px;
    //            color: #000;
    //          }
    //
    //          div:last-child {
    //            color: #666666;
    //            font-size: 14px;
    //          }
    //        }
    //
    //        .rightBottom {
    //          width: 180px;
    //          height: 54px;
    //          padding-bottom: 26px;
    //          color: #666666;
    //          font-weight: bold;
    //          font-size: 24px;
    //          border-bottom: 3px solid;
    //        }
    //      }
    //    }
    //
    //    .optionExplainContainer:hover {
    //      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    //    }
    //  }
    //}
  }
}
</style>
