<template>
  <div>
    <!--    <div class="recommendGoodsMain">-->
    <loginDialog ref="dialog"/>
    <!--      <nav>-->
    <!--        <h4>1688日本売れ筋</h4>-->
    <!--        <div class="iconBox">-->
    <!--          <div @click="shopCarouselChange('left')">-->
    <!--            <img alt="" src="../../assets/1688/flecha-izquierda.png" style="margin-left: 13px"/>-->
    <!--          </div>-->
    <!--          <div @click="shopCarouselChange('right')">-->
    <!--            <img alt="" src="../../assets/1688/flecha-izquierda-1.png" style="margin-left: 17px"/>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </nav>-->
    <!--      <div v-if="goodsList.length == 0">-->
    <!--        <el-carousel ref="shopCarousel" :autoplay="autoplay" :interval="3000" height="305px" trigger="click"-->
    <!--                     arrow="never" @mouseover.native="autoplay = false" @mouseleave.native="autoplay = true">-->
    <!--          <el-carousel-item>-->
    <!--            <div class="recommendGoodsBox">-->
    <!--              <div v-for="i in 6" :key="i" class="recommendGoods">-->
    <!--                <div class="imageBox">-->
    <!--                  <div class="notHaveData">-->
    <!--                    <i class="el-icon-loading"></i>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div style="margin-top: 20px">-->
    <!--                  <el-skeleton animated></el-skeleton>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-carousel-item>-->
    <!--        </el-carousel>-->
    <!--      </div>-->
    <!--      <div v-else>-->
    <!--        <el-carousel ref="shopCarousel" :autoplay="autoplay" :interval="3000" height="356px" trigger="click"-->
    <!--                     @mouseover.native="autoplay = false" @mouseleave.native="autoplay = true">-->
    <!--          <el-carousel-item v-for="(item, index) in goodsList" v-show="item.status" :key="index">-->
    <!--            <div class="recommendGoodsBox">-->
    <!--              <div v-for="(goodsItem,goodsIndex) in item" :key="goodsItem.goods_id"-->
    <!--                   :data-aplus-report="goodsItem.traceInfo"-->
    <!--                   class="recommendGoods" data-tracker="offer" @click="toCommodityDetails(goodsItem)">-->
    <!--                <div class="imageBox">-->
    <!--                  <el-image :src="goodsItem.imgUrl">-->
    <!--                    <div slot="error" class="image-slot">-->
    <!--                      <i class="el-icon-picture-outline"></i>-->
    <!--                    </div>-->
    <!--                    <div slot="placeholder">-->
    <!--                      <i class="el-icon-loading"></i>-->
    <!--                    </div>-->
    <!--                  </el-image>-->
    <!--                </div>-->
    <!--                <div style="padding: 0 12px">-->
    <!--                  <div :title="goodsItem.titleT" class="goodsTitle">-->
    <!--                    <span v-if="goodsItem.shopType==1688" class="type">1688</span>-->
    <!--                    {{ goodsItem.titleT }}-->
    <!--                  </div>-->
    <!--                  <div class="priceContainer">-->
    <!--                <span class="priceBox">-->
    <!--                  {{-->
    <!--                    goodsItem.goodsPrice-->
    <!--                  }}{{-->
    <!--                    $fanyi("元")-->
    <!--                  }}（{{-->
    <!--                    (goodsItem.goodsPrice-->
    <!--                        * exchangeRate).toFixed(0)-->
    <!--                  }}{{ $fanyi("円") }}）-->
    <!--                </span>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-carousel-item>-->
    <!--        </el-carousel>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="hotListContainer" style="margin-top: 60px;display: flex;">
      <div class="japaneseHotCommodity" style="margin-right: 30px">
        <div class="flexAndCenter japaneseHotCommodityHeader" style="justify-content: space-between">
          <div class="goodsType">
            <div>Amazon売れ筋</div>
            <img alt="" src="../../assets/1688/hot.gif">
          </div>
          <div class="cursorPointer color333 fontSize14" style="margin-right: 30px" @click="viewMore">
            {{ $fanyi('查看更多') }}>>
          </div>
        </div>
        <div class="childsContainer" style="margin-right: 30px">
          <div class="childsItemContainer">
            <div class="color999" style="margin-bottom: 10px">{{ $fanyi('按照主分类筛选') }}</div>
            <el-select v-model="leftValue" style="width: 306px;" @change="leftOptionChange">
              <el-option
                  v-for="item in leftOption"
                  :key="item.id"
                  :label="item.translate_name"
                  :value="item.chinese_name">
              </el-option>
            </el-select>
          </div>
          <div class="childsItemContainer" style="margin-left: 10px;">
            <div class="color999" style="margin-bottom: 10px">{{ $fanyi('按照子分类筛选') }}</div>
            <el-select v-model="leftFrom.keywords" style="width: 306px;" @change="getLeftHotSaleSearch">
              <el-option
                  v-for="item in leftChildrenOption"
                  :key="item.id"
                  :label="item.translate_name"
                  :value="item.chinese_name">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="tableHeader">
          <div class="color999 fontSize14">商品信息</div>
          <div class="color999 fontSize14" style="width: 169px;">{{ $fanyi('价格') }}</div>
          <div class="color999 fontSize14">販売数</div>
        </div>
        <div style="padding-right:30px">
          <div v-loading="leftFullscreenLoading">
            <div v-for="(item,index) in leftList" :key="index" class="goodItemContainer"
                 @click="toCommodityDetails(item.goodsId,item.shopType)">
              <div class="flexAndCenter" style="width: 395px;min-width: 395px">
                <img :src="item.imgUrl" alt="" class="goods_pic">
                <div class="type">1688</div>
                <div :title="item.goods_title" class="goodsTitle u-line">
                  {{ item.titleT }}
                </div>
              </div>
              <div class="price" style="width: 185px;text-align: left">{{ item.goodsPrice }}{{ $fanyi("元") }}（{{
                  (item.goodsPrice * exchangeRate).toFixed(0)
                }}{{ $fanyi("円") }}）
              </div>
              <div class="fontSize14 color333" style="width:60px;text-align: left">{{ item.monthSold }}</div>
              <el-popover placement="bottom" trigger="hover" width="260">
                <div>
                  類似品を検索
                </div>
                <div slot="reference" class="icon" @click.stop="imageSearchFun(item.imgUrl)">
                  <img alt="" src="../../assets/1688/indexImageSearch.svg">
                </div>
              </el-popover>
            </div>
            <div class="pageSwitchContainer">
              <div class="furnishText">1688 より、情報サポートを受けて提供させて頂いております</div>
              <div class="pageNum">
                <div> {{ leftFrom.page }} ページ目</div>
                <div><span @click="updatePage('left','minus')"> < </span><span
                    @click="updatePage('left','add')"> > </span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="japaneseHotCommodity">
        <div class="flexAndCenter japaneseHotCommodityHeader"
             style="justify-content: space-between;padding-right: 30px">
          <div class="goodsType">
            <div>楽天売れ筋</div>
            <img alt="" src="../../assets/1688/hot.gif">
          </div>
          <div class="cursorPointer color333 fontSize14" @click="viewMore">
            {{ $fanyi('查看更多') }}>>
          </div>
        </div>
        <div class="childsContainer" style="margin-right: 30px">
          <div class="childsItemContainer">
            <div class="color999" style="margin-bottom: 10px">{{ $fanyi('按照主分类筛选') }}</div>
            <el-select v-model="rightValue" style="width: 306px;" @change="rightOptionChange">
              <el-option
                  v-for="item in rightOption"
                  :key="item.id"
                  :label="item.translate_name"
                  :value="item.chinese_name">
              </el-option>
            </el-select>
          </div>
          <div class="childsItemContainer" style="margin-left: 10px">
            <div class="color999" style="margin-bottom: 10px">{{ $fanyi('按照子分类筛选') }}</div>
            <el-select v-model="rightFrom.keywords" style="width: 306px;" @change="getRightHotSaleSearch">
              <el-option
                  v-for="item in rightChildrenOption"
                  :key="item.id"
                  :label="item.translate_name"
                  :value="item.chinese_name">
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="tableHeader">
            <div class="color999 fontSize14">商品信息</div>
            <div class="color999 fontSize14" style="width: 169px;">{{ $fanyi('价格') }}</div>
            <div class="color999 fontSize14" style="width: 50px;">販売数</div>
          </div>
          <div v-loading="rightFullscreenLoading">
            <div v-for="(item,index) in rightList" :key="index" class="goodItemContainer"
                 @click="toCommodityDetails(item.goodsId,item.shopType)">
              <div class="flexAndCenter" style="width: 395px;min-width: 395px">
                <img :src="item.imgUrl" alt="" class="goods_pic">
                <div class="type">1688</div>
                <div :title="item.goods_title" class="goodsTitle u-line">
                  {{ item.titleT }}
                </div>
              </div>
              <div class="price" style="width: 169px;text-align: left">{{ item.goodsPrice }}{{ $fanyi("元") }}（{{
                  (item.goodsPrice * exchangeRate).toFixed(0)
                }}{{ $fanyi("円") }}）
              </div>
              <div class="fontSize14 color333" style="width:60px;text-align: left">{{ item.monthSold }}</div>
              <el-popover placement="bottom" trigger="hover" width="260">
                <div>
                  類似品を検索
                </div>
                <div slot="reference" class="icon" @click.stop="imageSearchFun(item.imgUrl)">
                  <img alt="" src="../../assets/1688/indexImageSearch.svg">
                </div>
              </el-popover>
            </div>
          </div>
          <div class="pageSwitchContainer" style="padding-right:30px">
            <div class="furnishText">1688 より、情報サポートを受けて提供させて頂いております</div>
            <div class="pageNum">
              <div>{{ rightFrom.page }} ページ目</div>
              <div><span @click="updatePage('right','minus')"> < </span><span
                  @click="updatePage('right','add')"> > </span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="hotListContainer">-->
    <!--      <div class="hotListHeaderContainer" style="margin-bottom: 30px">-->
    <!--        <div class="amazonHotList">-->
    <!--          <div class="header">-->
    <!--            <div>亚马逊热销榜</div>-->
    <!--            <img alt="" src="../../assets/1688/amazonIcon.png">-->
    <!--          </div>-->
    <!--          <div class="goodsList">-->
    <!--            <div v-for="(item,index) in list" :key="index" class="goodItemContainer" @click="toCommodityDetails(item)">-->
    <!--              <img :src="item.goods_pic" alt="" class="goods_pic">-->
    <!--              <img v-if="index==0||index==1||index==2"-->
    <!--                   :src="require(`../../assets/1688/${index+1}.png`)" class="topThreeIcon">-->
    <!--              <div class="goodItemBottomContainer">-->
    <!--                <div :title="item.goods_title" class="goodsTitle">-->
    <!--                  <span v-if="item.goods_type==1688" class="type">1688</span>-->
    <!--                  {{ item.goods_title }}-->
    <!--                </div>-->
    <!--                <div class="priceContainer">-->
    <!--                  {{ item.goods_price }}{{ $fanyi("元") }}（{{-->
    <!--                    (item.goods_price * exchangeRate).toFixed(0)-->
    <!--                  }}{{ $fanyi("円") }}）-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="theRakutenHotList">-->
    <!--          <div class="header">-->
    <!--            <div>乐天热销榜</div>-->
    <!--            <img alt="" src="../../assets/1688/theRakutenIcon.png">-->
    <!--          </div>-->
    <!--          <div class="goodsList">-->
    <!--            <div v-for="(item,index) in list" :key="index" class="goodItemContainer" @click="toCommodityDetails(item)">-->
    <!--              <img :src="item.goods_pic" alt="" class="goods_pic">-->
    <!--              <img v-if="index==0||index==1||index==2"-->
    <!--                   :src="require(`../../assets/1688/${index+1}.png`)" class="topThreeIcon">-->
    <!--              <div class="goodItemBottomContainer">-->
    <!--                <div :title="item.goods_title" class="goodsTitle">-->
    <!--                  <span v-if="item.goods_type==1688" class="type">1688</span>-->
    <!--                  {{ item.goods_title }}-->
    <!--                </div>-->
    <!--                <div class="priceContainer">-->
    <!--                  {{ item.goods_price }}{{ $fanyi("元") }}（{{-->
    <!--                    (item.goods_price * exchangeRate).toFixed(0)-->
    <!--                  }}{{ $fanyi("円") }}）-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="hotListFooterContainer">-->
    <!--        <div class="YaHooHotList">-->
    <!--          <div class="header">-->
    <!--            <div>YaHoo热销榜</div>-->
    <!--            <img alt="" src="../../assets/1688/YaHooIcon.png">-->
    <!--          </div>-->
    <!--          <div class="goodsList">-->
    <!--            <div v-for="(item,index) in list" :key="index" class="goodItemContainer" @click="toCommodityDetails(item)">-->
    <!--              <img :src="item.goods_pic" alt="" class="goods_pic">-->
    <!--              <img v-if="index==0||index==1||index==2"-->
    <!--                   :src="require(`../../assets/1688/${index+1}.png`)" class="topThreeIcon">-->
    <!--              <div class="goodItemBottomContainer">-->
    <!--                <div :title="item.goods_title" class="goodsTitle">-->
    <!--                  <span v-if="item.goods_type==1688" class="type">1688</span>-->
    <!--                  {{ item.goods_title }}-->
    <!--                </div>-->
    <!--                <div class="priceContainer">-->
    <!--                  {{ item.goods_price }}{{ $fanyi("元") }}（{{-->
    <!--                    (item.goods_price * exchangeRate).toFixed(0)-->
    <!--                  }}{{ $fanyi("円") }}）-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="zozoHotList">-->
    <!--          <div class="header">-->
    <!--            <div>ZOZO热销榜</div>-->
    <!--            <img alt="" src="../../assets/1688/zozo.png">-->
    <!--          </div>-->
    <!--          <div class="goodsList">-->
    <!--            <div v-for="(item,index) in list" :key="index" class="goodItemContainer" @click="toCommodityDetails(item)">-->
    <!--              <img :src="item.goods_pic" alt="" class="goods_pic">-->
    <!--              <img v-if="index==0||index==1||index==2"-->
    <!--                   :src="require(`../../assets/1688/${index+1}.png`)" class="topThreeIcon">-->
    <!--              <div class="goodItemBottomContainer">-->
    <!--                <div :title="item.goods_title" class="goodsTitle">-->
    <!--                  <span v-if="item.goods_type==1688" class="type">1688</span>-->
    <!--                  {{ item.goods_title }}-->
    <!--                </div>-->
    <!--                <div class="priceContainer">-->
    <!--                  {{ item.goods_price }}{{ $fanyi("元") }}（{{-->
    <!--                    (item.goods_price * exchangeRate).toFixed(0)-->
    <!--                  }}{{ $fanyi("円") }}）-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import loginDialog from "@/components/public/loginDialog";

export default {
  data() {
    return {
      goodsList: [],
      autoplay: true,
      rightFullscreenLoading: false,
      leftFullscreenLoading: false,
      translation_name: "",
      chinese_name: "",
      list: [],
      leftTotal: 0,
      leftFrom: {
        keywords: '',
        productCollectionId: '205451988',
        shop_type: 1688,
        page: 1,
        pageSize: 6
      },
      rightFrom: {
        keywords: '',
        productCollectionId: '205451985',
        shop_type: 1688,
        page: 1,
        pageSize: 6
      },
      leftValue: '',
      rightValue: '',
      leftOption: [],
      leftChildrenOption: [],
      rightOption: [],
      rightChildrenOption: [],
      leftList: [],
      rightList: [],
      rightTotal: 0
    };
  },
  components: {
    loginDialog
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    },
  },
  created() {
    this.$api.getHotSaleCates().then((res) => {
      if (res.code != 0) return this.$message.error(res.msg)
      this.leftFrom.keywords = res.data[0].childs[0].chinese_name;
      this.rightFrom.keywords = this.$fun.deepClone(res.data[1].childs[0].chinese_name);
      this.leftValue = res.data[0].chinese_name;
      this.rightValue = this.$fun.deepClone(res.data[1].chinese_name);
      this.leftOption = res.data;
      this.leftChildrenOption = res.data[0].childs;
      this.rightOption = res.data;
      this.rightChildrenOption = res.data[1].childs;
      this.getLeftHotSaleSearch();
      this.getRightHotSaleSearch();
    });
  },
  methods: {
    getLeftHotSaleSearch() {
      this.leftFullscreenLoading = true;
      this.$api.EuropeKeywordSearchProduct(this.leftFrom).then((res) => {
        this.leftFullscreenLoading = false;
        if (res.code != 0) return this.$message.error(res.msg);
        this.leftList = res.data.result.result;
        this.leftTotal = res.data.result.total;
      });
    },
    getRightHotSaleSearch() {
      this.rightFullscreenLoading = true;
      this.$api.EuropeKeywordSearchProduct(this.rightFrom).then((res) => {
        this.rightFullscreenLoading = false;
        if (res.code != 0) return this.$message.error(res.msg);
        this.rightList = res.data.result.result;
        this.rightTotal = res.data.result.total;
      });
    },
    leftOptionChange(e) {
      for (let i = 0; i < this.leftOption.length; i++) {
        if (e == this.leftOption[i].chinese_name) {
          this.leftChildrenOption = this.leftOption[i].childs;
          this.leftFrom.keywords = this.leftOption[i].childs[0].chinese_name;
          this.getLeftHotSaleSearch();
        }
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('首页爆品商品浏览量统计');
      }
    },
    rightOptionChange(e) {
      for (let i = 0; i < this.rightOption.length; i++) {
        if (e == this.rightOption[i].chinese_name) {
          this.rightChildrenOption = this.rightOption[i].childs;
          this.rightFrom.keywords = this.rightOption[i].childs[0].chinese_name;
          this.getRightHotSaleSearch();
        }
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('首页爆品商品浏览量统计');
      }
    },
    updatePage(direction, type) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('首页爆品商品浏览量统计');
      }
      if (direction === 'left') {
        if (type == 'add') {
          if (this.leftTotal < 6) return this.$message.error(this.$fanyi('没有下一页了'))
          this.leftFrom.page++
          this.getLeftHotSaleSearch()
        } else {
          if (this.leftFrom.page == 1) return this.$message.error(this.$fanyi('当前是第一页'))
          this.leftFrom.page--
          this.getLeftHotSaleSearch()
        }
      } else {
        if (type == 'add') {
          if (this.rightTotal < 6) return this.$message.error(this.$fanyi('没有下一页了'))
          this.rightFrom.page++
          this.getRightHotSaleSearch()
        } else {
          if (this.rightFrom.page == 1) return this.$message.error(this.$fanyi('当前是第一页'))
          this.rightFrom.page--
          this.getRightHotSaleSearch()
        }
      }
    },
    toCommodityDetails(key, val) {
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        return
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过首页爆品榜单进入商品详情页访问量统计');
        __bl.sum('通过主页商品进入商品详情页访问量统计');
      }
      this.$fun.newToCommodityDetails(key, val, true, 'index')
    },
    // 数据处理
    ProcessingShopData() {
      //console.log(23);
      let lack = 6 - (this.goodsList.length % 6); //取得店铺商品数离6个的倍数差多少个商品的数量
      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i]);
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);
      this.$forceUpdate();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == "left") {
        this.$refs.shopCarousel.prev();
      } else if (type == "right") {
        this.$refs.shopCarousel.next();
      }
    },
    imageSearchFun(img) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过首页爆品榜单进行图片搜索统计');
      }
      window.open("/CommoditySearch?type=imgSearch&imgUrl=" + img)
    },
    viewMore() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('查看更多访问量统计');
      }
      window.open("/goodsMonthlyFocus")
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";

.type {
  width: 46px;
  height: 18px;
  display: inline-block;
  background: #FF4000;
  border-radius: 2px;
  margin-right: 7px;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
  color: #fff;
}

.recommendGoodsMain {
  width: $pageWidth;
  margin: 100px auto;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    h4 {
      color: #333333;
      font-size: 24px;
      font-weight: 600;
    }

    .iconBox {
      display: flex;
      align-items: center;

      div {
        width: 42px;
        height: 42px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 12px;
          height: 20px;
        }
      }

      div:first-child {
        margin-right: 12px;
      }
    }
  }

  .recommendGoodsBox {
    display: flex;

    .recommendGoods {
      cursor: pointer;
      width: 265px;
      height: 356px;
      min-width: 265px;
      min-height: 356px;
      background-color: white;
      border: 1px solid #DFDFDF;
      border-radius: 6px;
      margin-right: 18px;
      transition: 0.3s;
      position: relative;
      overflow: hidden;

      .topThreeIcon {
        position: absolute;
        top: 0;
        right: 0;
      }

      .imageBox {
        width: 100%;
        height: 255px;
        margin-bottom: 5px;
        text-align: center;
        background-color: white;
        overflow: hidden;

        /deep/ .el-image {
          $imgSize: 255px;
          width: 265px;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }

      .goodsTitle {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #000000;
        font-family: "Open Sans", Sans-serif;
        font-size: 14px;
      }

      .priceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .priceBox {
          color: #B4272D;
          font-size: 18px;
        }
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgb(223, 223, 223);
      }
    }

    .recommendGoods:first-child {
      margin-left: 0;
    }

    .recommendGoods:last-child {
      margin-right: 0;
    }
  }

  .lookMore {
    padding: 22px 34px;
    text-align: right;
    color: #1a289d;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    i {
      font-weight: 800;
      font-size: 14px;
    }
  }
}

.hotListContainer {
  width: $pageWidth;
  margin: 0 auto 100px;

  .hotListHeaderContainer, .hotListFooterContainer {
    display: flex;
    align-items: center;
  }

  .japaneseHotCommodity {
    padding: 14px 0 18px 30px;
    box-sizing: border-box;
    width: 685px;
    border-radius: 6px;
    background: #fff;

    .furnishText {
      font-size: 12px;
      color: #999999;
      text-align: right;
    }

    .goodsType {
      margin-bottom: 28px;
      display: flex;

      div {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
      }

      img {
        height: 18px;
        flex-shrink: 0;
        margin-left: -1px;
        margin-top: -11px;
      }
    }

    .japaneseHotCommodityHeader div:last-child:hover {
      color: #B4272B;
    }

    .childsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .childsItemContainer {
        .childsItemName {
          font-size: 16px;
          color: #999999;
          margin-bottom: 11px;
        }
      }
    }

    .tableHeader {
      display: flex;
      align-items: center;
      margin-right: 30px;

      div:first-child {
        width: 400px;
      }
    }

    .pageSwitchContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pageNum {
        display: flex;
        align-items: center;

        div:first-child {
          font-size: 14px;
          color: #999999;
          margin-right: 20px;
        }

        div:last-child {
          margin-top: -4px;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }

  .goodItemContainer {
    padding: 5px;
    display: flex;
    height: 62px;
    border-radius: 4px;
    background: #FFFFFF;
    margin-bottom: 5px;
    position: relative;
    align-items: center;
    cursor: pointer;

    .goods_pic {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      margin-right: 12px;
    }

    .icon {
      display: none;
    }

    .goodsTitle {
      color: #000000;
      font-size: 14px;
      width: 220px;
    }

    .price {
      font-size: 14px;
      color: #B4272D;
    }

    .goodItemBottomContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .priceContainer {
        color: #B4272D;
        font-size: 18px;
        font-weight: bold;
      }

      .newPriceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div:first-child {
          color: #B4272D;
          font-size: 16px;
          font-weight: bold;
        }

        div:last-child {
          font-size: 14px;

          span:last-child {
            color: #B4272D !important;
            display: inline-block;
            margin: 0 2px;
          }
        }
      }
    }
  }

  .goodItemContainer:hover {
    background-color: rgba(0, 0, 0, 0.36);

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 16px;
      right: 14px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      background: #FFFFFF;
      border-radius: 4px;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  //.goodItemContainer:last-child {
  //  margin-bottom: 0;
  //}

  .amazonHotList, .theRakutenHotList, .zozoHotList, .YaHooHotList {
    width: 685px;
    border-radius: 6px;
    padding: 30px;
    box-sizing: border-box;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      div {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }

  .amazonHotList {
    background: linear-gradient(0deg, #FFFFFF, #FFEDD3);
    margin-right: 30px;
  }

  .theRakutenHotList {
    background: linear-gradient(0deg, #FFFFFF, #F9DBDB);
  }

  .YaHooHotList {

    background: linear-gradient(0deg, #FFFFFF, #F9E4E1);
    margin-right: 30px;
  }

  .zozoHotList {
    background: linear-gradient(0deg, #FFFFFF, #DDD7DD);
  }
}

/deep/ .el-carousel__arrow--right,
/deep/ .el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}

/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}

/deep/ .el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}

/deep/ .el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}

.notHaveData {
  $imgSize: 255px;
  width: 265px;
  height: $imgSize;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $imgSize/4;
  }
}
</style>
