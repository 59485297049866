<template>
  <div>
    <div class="bannerContainer">
      <div class="banner">
        <el-carousel :interval="5000" height="600px" trigger="click">
          <el-carousel-item
              v-for="(item, index) in bannerList"
              v-show="item.status"
              :key="index"
          >
            <!-- 假如图片有指定连接才可以点击跳转 -->
            <a v-if="item.location_url" :href="item.location_url" target="_blank" @click="addBannerClickCount">
              <img :src="item.pic_url" alt="" class="bannerImg"/>
            </a>
            <img v-else :src="item.pic_url" alt="" class="bannerImg"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div>
        <div class="companyInfo">
          <div class="label">代行専用電話番号</div>
          <div class="fontWeightBold colorB4272B fontSize18">06-7636-8120</div>
          <div class="label" style="margin-top: 38px">{{ $fanyi("工作时间") }}</div>
          <div>日本時間 09:30-18:30</div>
          <div>（土、日曜日及び中国の祝日を除く）</div>
        </div>
        <div class="japan">
          <div>代行サービス</div>
          <div></div>
        </div>
        <div class="OEM" @click="pageJump('https://www.rakumade.com/')">
          <div>OEM、ODMサービス</div>
          <div></div>
        </div>
        <div class="D2C" @click="pageJump('https://www.rakumart-2c.com/#/')">
          <div>無在庫サービス（D2C）</div>
          <div></div>
        </div>
      </div>
    </div>
    <div style="width: 1400px;margin: 0 auto">
      <div class="ourMain">
        <div class="ourMainOpt">
      <span class="blueNumberICon">
        <svg
            id="Layer_1"
            class="icon"
            style="enable-background: new 0 0 512 512"
            viewBox="0 0 512 512"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
          <circle class="st0" cx="256" cy="255.3" r="244"></circle>
          <g>
            <path
                class="st1"
                d="M291.2,358.8h-56.4V250c0-13.2,0.3-25.3,0.8-36.3c-3.5,4.2-7.8,8.6-12.9,13.2l-23.4,19.3l-28.8-35.4l70.6-57.5  h50.1L291.2,358.8L291.2,358.8z"
            ></path>
          </g>
        </svg>
      </span>
          <div class="optSpanBox" style="width: 144px;">
            <p>{{ $fanyi("成为Rakumart的会员") }}</p>
            <span class="registerIcon" @click="pageJump('/register')">
          {{ $fanyi("立即注册") }}>>
        </span>
          </div>
        </div>
        <div class="ourMainOpt">
      <span class="blueNumberICon">
        <svg
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            viewBox="0 0 512 512"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
          <circle class="st0" cx="256" cy="255.3" r="244"></circle>
          <g>
            <path
                class="st1"
                d="M325.2,351.9H176.8v-36.4l50-50.5c14.3-14.9,23.6-25.1,28-30.6c4.4-5.5,7.4-10.1,9.2-14.1  c1.7-3.9,2.6-8,2.6-12.3c0-5.3-1.7-9.5-5.2-12.6s-8.3-4.6-14.6-4.6c-6.4,0-12.9,1.8-19.5,5.5s-14,9.1-22.3,16.3l-30.4-35.5  c10.5-9.4,19.4-16.2,26.6-20.2c7.2-4.1,15-7.2,23.4-9.3s17.9-3.2,28.5-3.2c13.2,0,25.1,2.3,35.5,7s18.5,11.3,24.2,19.9  c5.7,8.6,8.6,18.3,8.6,29c0,8-1,15.4-3,22.2s-5.1,13.5-9.3,20.1c-4.2,6.6-9.8,13.5-16.7,20.8c-7,7.3-21.8,21.1-44.4,41.4v1.4h77.4  v45.7H325.2z"
            ></path>
          </g>
        </svg>
      </span>
          <div class="optSpanBox" style="width: 208px;">
            <p>{{ $fanyi("寻找产品下单，我们确认报价方案，您可进行付款") }}</p>
          </div>
        </div>
        <div class="ourMainOpt">
      <span class="blueNumberICon">
        <svg
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            viewBox="0 0 512 512"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
          <circle class="st0" cx="256" cy="255.3" r="244"></circle>
          <g>
            <path
                class="st1"
                d="M318.4,191.6c0,12.7-3.9,23.6-11.6,32.7c-7.7,9.1-18.9,15.7-33.6,19.8v0.8c34.1,4.3,51.2,20.4,51.2,48.4  c0,19-7.5,34-22.6,44.9s-36,16.4-62.8,16.4c-11,0-21.2-0.8-30.6-2.4c-9.4-1.6-19.5-4.5-30.4-8.7v-45.9c9,4.6,18.1,8,27.4,10.1  c9.3,2.2,17.8,3.3,25.7,3.3c12.1,0,21-1.7,26.5-5.1s8.3-8.7,8.3-16c0-5.6-1.4-9.9-4.3-12.9s-7.6-5.3-14-6.9s-14.8-2.3-25.2-2.3  h-12.6v-41.6h12.9c28.1,0,42.1-7.2,42.1-21.5c0-5.4-2.1-9.4-6.2-12c-4.2-2.6-9.7-3.9-16.7-3.9c-13.1,0-26.6,4.4-40.6,13.2l-23-37  c10.8-7.5,21.9-12.8,33.2-16s24.3-4.8,39-4.8c21.2,0,37.8,4.2,49.8,12.6S318.4,176.9,318.4,191.6z"
            ></path>
          </g>
        </svg>
      </span>
          <div class="optSpanBox" style="width: 225px;">
            <p>{{ $fanyi("在我们仓库进行质量把控与存储") }}</p>
          </div>
        </div>
        <div class="ourMainOpt">
      <span class="blueNumberICon">
        <svg
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            viewBox="0 0 512 512"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
          <circle class="st0" cx="256" cy="255.3" r="244"></circle>
          <g>
            <path
                class="st1"
                d="M323.8,312.3h-22.5v39.6h-54.6v-39.6h-81.6v-40.6l85.1-124.4h51.1V271h22.5V312.3z M246.7,271v-23.8  c0-4.5,0.2-10.5,0.5-18.1s0.6-11.6,0.8-12h-1.5c-3.2,7.2-6.8,13.9-10.8,20.2L213,271H246.7z"
            ></path>
          </g>
        </svg>
      </span>
          <div class="optSpanBox" style="width: 209px;">
            <p>{{ $fanyi("提出发货指令，我们进行物流咨询，给您提供报价方案，由您选择国际运输并付款") }}</p>
          </div>
        </div>
        <div class="ourMainOpt">
      <span class="blueNumberICon">
        <svg
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            viewBox="0 0 512 512"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
          <circle class="st0" cx="256" cy="255.3" r="244"></circle>
          <g>
            <path
                class="st1"
                d="M261.1,217.8c11.8,0,22.4,2.7,31.8,8.2c9.5,5.5,16.8,13.2,22.1,23.2c5.3,10,7.9,21.5,7.9,34.6  c0,22.9-6.9,40.4-20.6,52.6c-13.8,12.2-34.6,18.3-62.5,18.3c-23.9,0-43.4-3.7-58.5-11.1v-45.3c8.1,3.7,17.3,6.8,27.5,9.2  c10.2,2.4,19,3.6,26.4,3.6c10.3,0,18.1-2.2,23.6-6.5s8.3-10.8,8.3-19.2c0-7.8-2.8-13.9-8.4-18.3s-14.1-6.6-25.5-6.6  c-8.7,0-18.1,1.6-28.1,4.9l-20.3-10.1l7.7-108h116.7v46h-69.7l-2.5,27c7.3-1.4,12.5-2.2,15.5-2.3S258.5,217.8,261.1,217.8z"
            ></path>
          </g>
        </svg>
      </span>
          <div class="optSpanBox" style="width: 195px;padding:0;">
            <p>{{ $fanyi("等待产品运输到您指定的地点") }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--      公告内容-->
    <div class="holdOrderDialogVisibleContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="showInformDialogVisible"
          center
          width="700px">
        <InFormDetail ref="InFormDetail"/>
      </el-dialog>
    </div>
    <!--      问卷弹窗-->
    <div class="questionnaireDialogContainer">
      <el-dialog
          :visible.sync="showQuestionnaireDialogVisible"
          center
          width="880px">
        <div class="flexAndCenterAndCenter cursorPointer">
          <img src="../../assets/qaDialog.png" style="width: 750px" @click="questionnairePageJump">
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import InFormDetail from "@/views/UserCenter/views/inform/InFormDetail.vue";

export default {
  components: {InFormDetail},
  data() {
    return {
      bannerList: [],
      showQuestionnaireDialogVisible: false,
      showInformDialogVisible: false,
      mustParticipateQuestionnaireList: [],
    }
  },
  created() {
    this.getShopBanner();
    this.$api.mustParticipateQuestionnaire().then((result) => {
      if (result.msg !== '登录失效' && result.msg !== '現在のログインは無効です，再度ログインしてください。' && result.msg !== '現在のログインは無効です，再度ログインしてください') {
        if (result.data.length > 0) {
          this.showQuestionnaireDialogVisible = true;
        }
      }
      this.mustParticipateQuestionnaireList = result.data;
    })
  },
  methods: {
    getShopBanner() {
      this.$api.getShopBanner().then((res) => {
        this.bannerList = res.data;
      });
    },
    pageJump(url) {
      window.open(url);
    },
    addBannerClickCount() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('客户点击banner数量');
      }
    },
    //问卷调查页面跳转
    questionnairePageJump() {
      for (let i = 0; i < this.mustParticipateQuestionnaireList.length; i++) {
        let href = "/questionnaire?id=" + this.mustParticipateQuestionnaireList[i].questionnaire_id;
        window.open(href);
      }
      this.showQuestionnaireDialogVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/mixin.scss";

.ourMain {
  width: $pageWidth;
  margin: 0 auto 30px;
  display: flex;
  justify-content: space-between;

  .ourMainOpt {
    // width: 287.95px;
    height: 79.39px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 15px;
    font-weight: 400;

    .blueNumberICon {
      width: 22.88px;
      height: 22.88px;
      padding: 0;
      margin-right: 5px;

      .icon {
        width: 22.88px;
        height: 22.88px;
      }
    }

    .optSpanBox {
      width: 239.95px;

      .registerIcon {
        color: #B4272B;
        text-decoration: underline;
      }

      span {
        text-decoration: underline;
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;

        i {
          margin-left: 12px;
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
  }
}

.bannerContainer {
  width: 1400px;
  margin: 18px auto 24px;
  display: flex;

  .banner {
    width: 1068px;
    margin-right: 12px;
    border-radius: 6px;
    overflow: hidden;

    .bannerImg {
      min-width: 1068px;
      max-width: 1068px;
      width: 1068px;
      height: 100%;
      border-radius: 6px;
    }

    // margin: 20px 30px 0 20px;
    // 轮播图两侧样式
    /deep/ .el-carousel__arrow--right,
    /deep/ .el-carousel__arrow--left {
      width: 41px;
      height: 41px;
      background: #90909f !important;
      opacity: 0.75;
      border-radius: 50%;
      z-index: 3;

      &:hover {
        opacity: 1;
      }
    }

    /deep/ .el-carousel__arrow {
      background: rgba(255, 255, 255, 0.5);
      text-align: center;

      i {
        color: white;
        font-size: 23px;
        font-weight: 700;
      }
    }

    /deep/ .el-carousel__button {
      width: 12px;
      height: 12px;
      background: #ffffff;
      border-radius: 50%;
    }

    /deep/ .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
    .el-carousel__button {
      outline: 3px solid #B4272B;
      background-color: transparent;
    }
  }

  .companyInfo {
    width: 320px;
    height: 220px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 37px;

    .label {
      font-size: 14px;
      color: #999999;
      margin-bottom: 12px;
    }
  }

  .D2C, .OEM, .japan {
    width: 320px;
    height: 116px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    div:first-child {
      padding: 40px 0 12px;
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    div:last-child {
      width: 26px;
      height: 4px;
      background: #B4272B;
    }
  }

  .japan {
    background: linear-gradient(0deg, #FFFFFF, #FFE7E8);
    margin: 12px 0 10px;
  }

  .OEM {
    background: linear-gradient(0deg, #FFFFFF, #E5F1FF);
    margin-bottom: 10px;

    div:last-child {
      background: #113E74 !important;
    }
  }

  .D2C {
    background: linear-gradient(0deg, #FFFFFF, #FFFAE9);

    div:last-child {
      background: #F3C622 !important;
    }
  }
}
</style>