<template>
  <div class="netEaseYanXuanPage">
    <div class="netEaseYanXuanPageContent">
      <el-link :underline="false" href="https://blog.rakumart.com/#/detail?id=60" target="_blank">
        <header></header>
      </el-link>
      <section class="flexAndCenter">
        <div v-for="(item,index) in menuList" :key="index" class="menuContainer">
          <el-popover :visible-arrow="false" placement="bottom" popper-class="subListPopperClass" trigger="hover">
            <div style="padding:0 38px;display: flex;flex-wrap: wrap">
              <div v-for="(subItem,subIndex) in item.sub_list" :key="subItem.id"
                   :style="subIndex===item.sub_list.length-1?'margin-right:0':''"
                   class="subListContainer u-line">
                <div class="title">{{ subItem.name_jp }}</div>
                <div>
                  <div v-for="childrenSubItem in subItem.sub_list"
                       :key="childrenSubItem.id"
                       class="childrenSubContainer"
                       @click="pageJump(item,childrenSubItem)">
                    {{ childrenSubItem.name_jp }}
                  </div>
                </div>
              </div>
            </div>
            <div slot="reference" class="headerMenu u-line" @click="pageJump(item,item)">{{ item.name_jp }}</div>
          </el-popover>
        </div>
      </section>
      <footer class="flexAndCenter">
        <div v-for="(item,index) in goodsList" :key="index"
             class="goodsContainer" @click="$fun.toCommodityDetails(item.SPUID, item.goods_type)">
          <img :src="item.primaryPicUrl" alt="">
          <div class="goodsTitleAndGoodsPrice">
            <div>{{ item.TranslationName }}</div>
            <div>{{ item.price }}{{ $fanyi('元') }}（{{ item.jp_price }}{{ $fanyi('円') }}）</div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [],
      goodsList: [],
    }
  },
  created() {
    this.$api.homeCategories().then((result) => {
      this.menuList = result.data;
    })
    this.$api.neteaseRecommendedGoods().then((result) => {
      this.goodsList = result.data;
    })
  },
  methods: {
    pageJump(item, childrenSubItem) {
      window.open(`/neteaseClassifyDetails?id=${item.class_id}&childrenId=${childrenSubItem.class_id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.netEaseYanXuanPage {
  width: 100%;
  background: #F4F0EA;

  .netEaseYanXuanPageContent {
    margin: 0 auto;
    width: 1400px;
    padding: 50px 0;

    header {
      height: 210px;
      width: 1400px;
      background: url("../../assets/foot-img/optionExplain/wyBackground.jpg") no-repeat;
      background-size: 100% 100%;
    }

    section {
      padding: 35px 52px 0 50px;
      flex-wrap: wrap;

      .menuContainer {
        width: 185px;
        padding-bottom: 20px;

        .headerMenu {
          color: #000000;
          font-size: 18px;
          height: 33px;
          line-height: 33px;
          text-align: center;
          width: 100%;
          cursor: pointer;
        }

        .headerMenu:hover {
          color: #CC9756;
          border-bottom: 2px solid #CC9756;
        }

        .footerMenu {
          cursor: pointer;
          margin-top: 7px;
          font-size: 14px;
          color: #666666;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
        }
      }
    }

    footer {
      justify-content: space-between;
      width: 100%;

      .goodsContainer {
        width: 265px;
        height: 345px;
        background: #FFFFFF;
        cursor: pointer;
        transition: 0.3s;

        img {
          width: 265px;
          height: 265px;
        }

        .goodsTitleAndGoodsPrice {
          padding: 8px 20px 0 17px;

          div:first-child {
            width: 228px;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-bottom: 8px;
            color: #000000;
            font-size: 12px;
          }

          div:last-child {
            color: #B4272D;
            font-size: 12px;
          }
        }
      }

      .goodsContainer:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px #dfdfdf;
      }
    }
  }
}
</style>
